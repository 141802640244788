<template>
  <div class="card-body position-relative overflow-hidden">
    <div class="font-size-36 font-weight-bold text-dark line-height-1 mt-2">
      $240.56
    </div>
    <div class="text-uppercase mb-1">Outcome</div>
    <!-- <div :class="$style.chartContainer">[TODO_CHARTS]</div> -->
  </div>
</template>
<script>
export default {
  name: 'VbChart11v1',
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
